/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import { getFormFields, getBasinEndpoint } from "../../lib/functions";

import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SponsorSlider from "../components/SponsorSlider";
import ContactForm from "../components/ContactForm";
import LoginPrompt from "../components/LoginPrompt";

const Page = ({ data }) => {
  const { title, content, seo } = data.wpPage;
  const user =
    typeof window !== "undefined"
      ? JSON.parse(localStorage?.getItem("user"))
      : null;
  const { form } = data.wpPage.afterContentForm;
  const { sidebarMenus } = data.wpPage.sidebarMenuSelect;

  return (
    <Layout seo={seo}>
      <PageWrapper>
        <ContentWrapper>
          {!!user ? (
            <>
              <PageContent content={content} />
              {form && (
                <ContactForm
                  fields={getFormFields(form ? form : "contact")}
                  title={form}
                  endpointOverride={form ? getBasinEndpoint(form) : null}
                />
              )}
            </>
          ) : (
            <LoginPrompt />
          )}
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs menus={sidebarMenus} />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      sidebarMenuSelect {
        sidebarMenus {
          menu
        }
      }
      afterContentForm {
        form: afterContentForm
      }
    }
  }
`;
