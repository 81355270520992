import React from "react";
import tw from "twin.macro";
import Content from "./Content";

const Section = tw.section`flex flex-col w-full relative`;
const Title = tw.h1`flex text-2xl text-offblack flex-row relative items-end whitespace-nowrap font-din mb-4 font-normal after:(content[''] bg-offblack bg-opacity-50 absolute left-0 bottom-0 w-full h-0.5 flex)`;

const LoginPrompt = ({ ...rest }) => {
  return (
    <Section {...rest}>
      <Title>Members Only</Title>
      <Content>
        <p>
          This page is restricted for PBCJA members only. Please login or signup
          to view the content.
        </p>
      </Content>
    </Section>
  );
};

export default LoginPrompt;
